import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import { canAccessCourseCatalog } from '../../services/currentUser';
import { CLASSES } from '../../constants/routes';
import MyCoursesList from '../Classes/MyCoursesList';

const MyCourses = ({
  title = 'My Courses'
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const userCourses = useSelector((state) => state.userCourses);
  // Misc
  const enableCourseCatalog = pathOr(null, ['enableCourseCatalog'], organization);

  return (
    <div className="card h-100">
      <div className="card-header bg-light p-3">
        <h5 className="m-0">
          <i className="fas fa-laptop-code mr-1" /> { title }
        </h5>
      </div>
      <div className="card-content overflow-hidden h-100">
        {userCourses && userCourses.length > 0 ? (
          <MyCoursesList />
        ) : (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="text-center py-4">
              <h4>
                <i className="fas fa-laptop-code mr-1" />
              </h4>
              <p>
                Get started by enrolling in a Course.
              </p>
              {canAccessCourseCatalog({ enableCourseCatalog }) && (
                <Link
                  to={CLASSES}
                  title="View Courses"
                  className="btn btn-primary btn-md"
                >
                  View Courses
                </Link>
              )}
            </div>
          </div>
        )}
      </div>

      {canAccessCourseCatalog({ enableCourseCatalog }) && (
        <div className="card-footer p-3 d-flex align-items-center justify-content-center bg-light">
          {userCourses && userCourses.length > 0 ? (
            <Link
              className="btn btn-link p-0"
              to={CLASSES}
              title="View all Courses"
            >
              View all Courses
            </Link>
          ) : (
            <div>Not enrolled in any courses.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyCourses;
