import React from 'react';
import { useSelector } from 'react-redux';
import SendEmail from './SendEmail';
import Reload from './Reload';
import Welcome from './Welcome';
import BillingAgreement from './BillingAgreement';
import UpgradeMembership from './UpgradeMembership';
import EnrollExternalRegisteredClass from './EnrollExternalRegisteredClass';
import ChooseUnitCodes from './ChooseClassCatalogIds';
import CourseComplete from './CourseComplete';
import TopicComplete from './TopicComplete';
import CourseTopics from './CourseTopics';
import GuidedQuizIncorrect from './GuidedQuizIncorrect';
import SearchSelectUsers from './SearchSelectUsers';
import ApprenticeInfo from './ApprenticeInfo';
import UserSignature from './UserSignature';
import ImageCrop from './ImageCrop';
import ChangeEmailCurrentUser from './ChangeEmailCurrentUser';
import ChangeUserPassword from './ChangeUserPassword';
import FeatureFlagNotice from './FeatureFlagNotice';
import AppsMenu from './AppsMenu';
import NotificationsMenu from './NotificationsMenu';
import {
  MODAL_KEY_APPRENTICE_INFO,
  MODAL_KEY_BILLING_AGREEMENT,
  MODAL_KEY_CHANGE_EMAIL,
  MODAL_KEY_CHANGE_PASSWORD,
  MODAL_KEY_CHOOSE_UNIT_CODES,
  MODAL_KEY_COURSE_COMPLETE,
  MODAL_KEY_COURSE_MENU,
  MODAL_KEY_ENROLL_EXTERNAL_REGISTERED_CLASS,
  MODAL_KEY_FEATURE_FLAG_NOTICE,
  MODAL_KEY_GUIDED_QUIZ_INCORRECT,
  MODAL_KEY_IMAGE_CROP,
  MODAL_KEY_NOTIFICATIONS_MENU,
  MODAL_KEY_RELOAD,
  MODAL_KEY_SEARCH_SELECT_USERS,
  MODAL_KEY_SEND_EMAIL,
  MODAL_KEY_TOPIC_COMPLETE,
  MODAL_KEY_UPGRADE_MEMBERSHIP,
  MODAL_KEY_USER_SIGNATURE,
  MODAL_KEY_WELCOME,
  MODAL_KEY_APPS_MENU,
  MODAL_KEY_VELA_HANDOFF
} from '../../constants/modal';
import VelaHandoff from './VelaHandoff';

const Modals = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);

  if (!currentUser?.id || !currentModal?.visible) {
    return (
      <>
        {currentModal?.key === MODAL_KEY_FEATURE_FLAG_NOTICE && <FeatureFlagNotice />}
      </>
    );
  }

  return (
    <>
      {currentModal?.key === MODAL_KEY_FEATURE_FLAG_NOTICE && <FeatureFlagNotice />}

      {currentModal?.key === MODAL_KEY_NOTIFICATIONS_MENU && <NotificationsMenu />}

      {currentModal?.key === MODAL_KEY_SEND_EMAIL && <SendEmail />}

      {currentModal?.key === MODAL_KEY_IMAGE_CROP && <ImageCrop />}

      {currentModal?.key === MODAL_KEY_APPRENTICE_INFO && <ApprenticeInfo />}

      {currentModal?.key === MODAL_KEY_USER_SIGNATURE && <UserSignature />}

      {currentModal?.key === MODAL_KEY_COURSE_COMPLETE && <CourseComplete />}

      {currentModal?.key === MODAL_KEY_TOPIC_COMPLETE && <TopicComplete />}

      {currentModal?.key === MODAL_KEY_GUIDED_QUIZ_INCORRECT && (
        <GuidedQuizIncorrect />
      )}

      {currentModal?.key === MODAL_KEY_UPGRADE_MEMBERSHIP && (
        <UpgradeMembership />
      )}

      {currentModal?.key === MODAL_KEY_ENROLL_EXTERNAL_REGISTERED_CLASS && (
        <EnrollExternalRegisteredClass />
      )}

      {currentModal?.key === MODAL_KEY_SEARCH_SELECT_USERS && <SearchSelectUsers />}

      {currentModal?.key === MODAL_KEY_COURSE_MENU && <CourseTopics />}

      {currentModal?.key === MODAL_KEY_CHOOSE_UNIT_CODES && <ChooseUnitCodes />}

      {currentModal?.key === MODAL_KEY_WELCOME && <Welcome />}

      {currentModal?.key === MODAL_KEY_BILLING_AGREEMENT && <BillingAgreement />}

      {currentModal?.key === MODAL_KEY_RELOAD && <Reload />}

      {currentModal?.key === MODAL_KEY_CHANGE_EMAIL && (
        <ChangeEmailCurrentUser />
      )}

      {currentModal?.key === MODAL_KEY_CHANGE_PASSWORD && (
        <ChangeUserPassword />
      )}
      {currentModal?.key === MODAL_KEY_APPS_MENU && <AppsMenu />}
      {currentModal?.key === MODAL_KEY_VELA_HANDOFF && <VelaHandoff />}
    </>
  );
};

export default Modals;
