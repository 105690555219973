import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { findObjectByKey } from '@apprentage/utils';
import { resetCurrentModal, setCurrentModal } from '../../../../actions/Modals';
import { takeSessionAttendance } from '../../../../actions/Users';
// import findObjectIndexByKey from '../../../../utils/findObjectIndexByKey';
import CountdownTimer from '../../../CountdownTimer';
import hasMany from '../../../../utils/hasMany';
import isPublished from '../../../../utils/isPublished';
import { prevSelectedUnitCodes } from '../../../../services/user';
import { courseCohortUserHasEnrolled, activeCohorts } from '../../../../services/courses';
import { courseAttendanceByCohort } from '../../../../services/attendance';
import { USER, USER_CHECK } from '../../../../constants/icons';
import { MODAL_KEY_CHOOSE_UNIT_CODES } from '../../../../constants/modal';
import './style.css';

const SessionAttendanceCheckIn = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  // Local State
  const [loading, setLoading] = useState(false);
  const [attendanceButtonVisible, setAttendanceButtonVisible] = useState(true);
  const [attendanceSession, setAttendanceSession] = useState(0);

  if (!course || !currentUser) return null;

  const { attendance, cohortIds } = currentUser;
  const { id: classId, isLive } = course; // TODO move isLive to cohort.

  if (!courseCohorts) return null;

  // COHORTS
  const userCourseCohorts = cohortIds && courseCohorts && courseCohorts.filter((cc) => cohortIds.includes(cc.id));
  const userCohorts = activeCohorts(userCourseCohorts);
  const activeCohort = courseCohortUserHasEnrolled({ userCohorts, courseCohorts });

  if (!activeCohort) return null; // TODO redirect to class dashboard

  const {
    id: cohortId,
    title: cohortTitle,
    eUnitTracking, // TODO cohort flatten (pull eUnitTracking by cohortId or migrate into Cohort)
    eUnits // TODO cohort flatten (pull eUnits by cohortId or migrate into Cohort)
  } = activeCohort;

  if (!isPublished(eUnitTracking)) return null;

  // TODO cohort flatten
  const attendancePerSession = pathOr(null, ['fields', 'attendancePerSession'], eUnitTracking);

  const {
    fields: { // TODO cohort flatten
      currentSession,
      trackingInterval
    }
  } = eUnitTracking;

  const handleTakeAttendanceCallback = () => {
    setLoading(false);
    setAttendanceSession(attendanceSession + 1);
    setAttendanceButtonVisible(false);

    dispatch(resetCurrentModal());
  };

  const handleTakeAttendance = (eUnitCodes) => {
    if (eUnitCodes && eUnitCodes.length) {
      // If User has checked in already for session and unit codes are present
      setLoading(true);

      const eUnitIds = eUnitCodes || prevSelectedUnitCodes({
        classId, cohortId, eUnits, attendance
      });

      dispatch(takeSessionAttendance({
        eUnitCodes: eUnitIds,
        cohortId,
        cohortTitle
      })).then((updatedUser) => {
        handleTakeAttendanceCallback(updatedUser);
      });

      return false;
    }
  };

  const chooseUnitCodes = () => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_CHOOSE_UNIT_CODES,
      data: {
        eUnitTracking,
        eUnits,
        // TODO rework using User educationUnitIds
        selectedUnitCodes: prevSelectedUnitCodes({
          classId, cohortId, eUnits, attendance
        }),
        callback: handleTakeAttendance
      }
    }));
  };

  const handleOnTimeExpired = () => {
    setAttendanceButtonVisible(true);
    chooseUnitCodes();
  };

  const allCheckedIn = () => {
    if (attendance !== undefined && attendance[classId] !== undefined) {
      const cohortAttendance = courseAttendanceByCohort({
        courseAttendance: attendance[classId],
        cohortId
      });

      if (!cohortAttendance) return false;

      const session = findObjectByKey(cohortAttendance, 'session', currentSession); // TODO use .find() ?
      const records = pathOr(null, ['records'], session);

      if (session && records && Array.isArray(records) && records.length === attendancePerSession) {
        return true;
      }
    }

    return false;
  };

  if (!isLive) {
    return (
      <div className="SessionAttendanceCheckIn-container">
        <span className="label mr-2">
          Session {currentSession}:
        </span>
        <span>
          Check-in once Class is Live.
        </span>
      </div>
    );
  }

  const buttonClassName = loading ? 'btn-outline-secondary' : 'btn-primary';

  if (allCheckedIn() || (attendancePerSession === attendanceSession)) {
    return (
      <Fragment>
        <span className="label mr-2">
          Session {currentSession}:
        </span>
        <div
          className="btn btn-sm btn-primary disabled pointer-events-none text-white"
        >
          <i className={USER_CHECK} /> All Checked in!
        </div>
      </Fragment>
    );
  }

  // const selectedUnitCodes = prevSelectedUnitCodes({ classId, cohortId, eUnits, attendance });

  return (
    <div className="SessionAttendanceCheckIn-container">
      {(attendanceButtonVisible || attendanceSession === 0) ? (
        <Fragment>
          <span className="font-weight-bold mr-2">
            Session {currentSession}:
          </span>

          <div
            className={`${hasMany(eUnits) ? 'btn-group' : ''}`}
          >
            <button
              onClick={() => {
                chooseUnitCodes();
              }}
              type="button"
              className={`btn btn-sm ${buttonClassName}`}
              title="Check In"
              disabled={loading}
            >
              <i className={`${USER} d-none d-sm-inline`} /> {loading ? 'Checking In...' : 'Check In'}
            </button>

            {/* <button
              type="button"
              className={`btn btn-sm ${buttonClassName} dropdown-toggle dropdown-toggle-split ${hasMany(eUnits) ? '' : 'd-none'}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled={loading}
            >
              <span className="sr-only">Toggle Dropdown</span>
            </button> */}

            {/* <div
              className="dropdown-menu dropdown-menu-right p-0"
            >
              <div className="card-header px-3 py-2">
                <h6 className="m-0 text-nowrap">
                  Class Codes
                </h6>
              </div>

              <div className="card-content">
                {eUnits && eUnits.map((unit, i) => {
                  const { fields: { code } } = unit;

                  return (
                    <div
                      key={code}
                      className={`py-2 px-3 text-nowrap ${i === 0 ? '' : 'border-top'}`}
                    >
                      {selectedUnitCodes && selectedUnitCodes.includes(code) ? (
                        <i className="fas fa-check-circle mr-2 text-keppel" />
                      ) : (
                        <i className="far fa-check-circle mr-2 text-muted" style={{ opacity: '.25' }} />
                      )}
                      {code}
                    </div>
                  )
                })}
              </div>
              <button
                className="btn btn-link d-block w-100 border-top text-nowrap"
                type="button"
                onClick={chooseUnitCodes}
                type="button"
              >
                Change Selection
              </button>
            </div> */}
          </div>
        </Fragment>
      ) : (
        <CountdownTimer
          text="Time till Next Self Check-in:"
          mobileText="Next Check-in:"
          minutes={trackingInterval}
          onTimeExpired={handleOnTimeExpired}
        />
      )}
    </div>
  );
};

export default SessionAttendanceCheckIn;
