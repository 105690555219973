import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

const OrgLocationCell = ({ value: employerIds }) => {
  const locations = useSelector((state) => state.locations);

  if (!locations.hash) {
    return null;
  }

  const employerNames = employerIds && employerIds.map((employerId) => {
    const locationName = pathOr(null, ['name'], locations.hash[employerId]);
    return locationName || 'unknown';
  });

  return (
    <div>
      {employerNames && employerNames.map((employerName, i) => (
        <div
          key={`employerName-${i}`}
          className='overflow-hidden text-nowrap'
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '250px'
          }}
        >
          {employerName}
        </div>
      ))}
    </div>
  );
};

export default OrgLocationCell;
