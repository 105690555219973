import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as analytics from '../../utils/analytics';
import Profile from './Profile';
import MyTrainingPrograms from './MyTrainingPrograms';
import './style.css';

const StudentDashboard = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const organization = useSelector((state) => state.organization);
  // misc
  const userId = currentUser?.id || null;
  const locationId = userLocation?.id || null;
  const locationName = userLocation?.name || null;
  const orgId = organization?.id || null;
  const orgName = organization?.name || null;

  useEffect(() => {
    if (orgId) {
      const analyticsData = {
        page_title: 'Dashboard',
        page_type: 'Training Program Dashboard',
        orgId,
        orgName,
        ...(userId ? { userId } : {}),
        ...(locationId ? { locationId } : {}),
        ...(locationName ? { orgLocationName: `${orgName} ${locationName}` } : {})
      };

      analytics.logEvent('page_view', analyticsData);
      analytics.logEvent('dashboard_training_view', analyticsData);
    }
  }, [userId, orgId, locationId, orgName, locationName]);

  if (!currentUser?.id || !organization?.id) {
    return null;
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-4 mb-4">
          <Profile className="h-100" />
        </div>
        <div className="col-sm-12 col-md-8 mb-4">
          <MyTrainingPrograms />
        </div>
      </div>
    </>
  );
};

export default StudentDashboard;
